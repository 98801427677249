<template>
  <div class="grid gap-4 grid-cols-1">
    <template v-for="provider in providers" :key="provider.id">
      <a
        v-if="provider.id === 'github'"
        href="#"
        class="flex w-full items-center rounded-md bg-[#24292F] px-3 py-2 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
        :class="loading ? 'opacity-50 pointer-events-none' : ''"
        @click.prevent="handleClick(provider)"
      >
        <div class="w-[35px]">
          <svg
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <span class="text-sm font-semibold leading-6">GitHub</span>
      </a>
      <a
        v-if="provider.id === 'google'"
        href="#"
        class="flex w-full items-center rounded-md bg-[#4285f4] text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
        :class="loading ? 'opacity-50 pointer-events-none' : ''"
        @click.prevent="handleClick(provider)"
      >
        <div class="w-[47px]">
          <svg
            class="w-10 h-10 rounded"
            viewBox="0 0 46 46"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          >
            <!-- Generator: Sketch 3.3.3 (12081) - http://www.bohemiancoding.com/sketch -->
            <title>btn_google_dark_focus_ios</title>
            <desc>Created with Sketch.</desc>
            <defs>
              <filter
                x="-50%"
                y="-50%"
                width="200%"
                height="200%"
                filterUnits="objectBoundingBox"
                id="filter-1"
              >
                <feOffset
                  dx="0"
                  dy="1"
                  in="SourceAlpha"
                  result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                  stdDeviation="0.5"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.168 0"
                  in="shadowBlurOuter1"
                  type="matrix"
                  result="shadowMatrixOuter1"
                ></feColorMatrix>
                <feOffset
                  dx="0"
                  dy="0"
                  in="SourceAlpha"
                  result="shadowOffsetOuter2"
                ></feOffset>
                <feGaussianBlur
                  stdDeviation="0.5"
                  in="shadowOffsetOuter2"
                  result="shadowBlurOuter2"
                ></feGaussianBlur>
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.084 0"
                  in="shadowBlurOuter2"
                  type="matrix"
                  result="shadowMatrixOuter2"
                ></feColorMatrix>
                <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                  <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
              </filter>
              <rect
                id="path-2"
                x="0"
                y="0"
                width="40"
                height="40"
                rx="2"
              ></rect>
              <rect
                id="path-3"
                x="5"
                y="5"
                width="38"
                height="38"
                rx="1"
              ></rect>
            </defs>
            <g
              id="Google-Button"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              sketch:type="MSPage"
            >
              <g
                id="9-PATCH"
                sketch:type="MSArtboardGroup"
                transform="translate(-668.000000, -219.000000)"
              ></g>
              <g
                id="btn_google_dark_focus"
                sketch:type="MSArtboardGroup"
                transform="translate(-1.000000, -1.000000)"
              >
                <rect
                  id="Rectangle-14"
                  fill-opacity="0.3"
                  fill="#4285F4"
                  sketch:type="MSShapeGroup"
                  x="1"
                  y="1"
                  width="46"
                  height="46"
                ></rect>
                <g
                  id="button"
                  sketch:type="MSLayerGroup"
                  transform="translate(4.000000, 4.000000)"
                  filter="url(#filter-1)"
                >
                  <g id="button-bg">
                    <use
                      fill="#4285F4"
                      fill-rule="evenodd"
                      sketch:type="MSShapeGroup"
                      xlink:href="#path-2"
                    ></use>
                    <use fill="none" xlink:href="#path-2"></use>
                    <use fill="none" xlink:href="#path-2"></use>
                    <use fill="none" xlink:href="#path-2"></use>
                  </g>
                </g>
                <g id="button-bg-copy">
                  <use
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    sketch:type="MSShapeGroup"
                    xlink:href="#path-3"
                  ></use>
                  <use fill="none" xlink:href="#path-3"></use>
                  <use fill="none" xlink:href="#path-3"></use>
                  <use fill="none" xlink:href="#path-3"></use>
                </g>
                <g
                  id="logo_googleg_48dp"
                  sketch:type="MSLayerGroup"
                  transform="translate(15.000000, 15.000000)"
                >
                  <path
                    d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
                    id="Shape"
                    fill="#4285F4"
                    sketch:type="MSShapeGroup"
                  ></path>
                  <path
                    d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
                    id="Shape"
                    fill="#34A853"
                    sketch:type="MSShapeGroup"
                  ></path>
                  <path
                    d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
                    id="Shape"
                    fill="#FBBC05"
                    sketch:type="MSShapeGroup"
                  ></path>
                  <path
                    d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
                    id="Shape"
                    fill="#EA4335"
                    sketch:type="MSShapeGroup"
                  ></path>
                  <path
                    d="M0,0 L18,0 L18,18 L0,18 L0,0 Z"
                    id="Shape"
                    sketch:type="MSShapeGroup"
                  ></path>
                </g>
                <g id="handles_square" sketch:type="MSLayerGroup"></g>
              </g>
            </g>
          </svg>
        </div>
        <div
          class="text-sm leading-6 font-bold"
          style="font-family: 'Roboto', sans-serif"
        >
          Sign in with Google
        </div>
      </a>
    </template>
  </div>
</template>

<script setup lang="ts">
const { data: providers } = await useFetch('/api/auth/providers')

defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['select'])

const handleClick = (provider) => {
  emit('select', { provider })
}
</script>
